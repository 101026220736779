import ApplicationController from './application_controller'
import { pickDateSeveralInputs, pickTimeSeveralInputs } from '../admin/pickDateSeveralInputs';


export default class extends ApplicationController {
    static targets = ['timefrom', 'timeto']

    connect() {
        console.log("TimeController connected")
        if (!!document.querySelector('.datepicker')) {
            pickDateSeveralInputs();
          }
          if (!!document.querySelector('.timepicker')) {
            pickTimeSeveralInputs();
          }
    }

    updateTimeTo() {
        const timeFromValue = this.timefromTarget.value;
        if (!timeFromValue) return;

        const [hourStr, minuteStr] = timeFromValue.split(':');
        let hours = parseInt(hourStr, 10);
        let minutes = parseInt(minuteStr, 10);

        let totalMinutes = hours * 60 + minutes + 90;

        totalMinutes %= 1440;

        const newHours = Math.floor(totalMinutes / 60);
        const newMinutes = totalMinutes % 60;

        const formattedTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;

        this.timetoTarget.value = formattedTime;
    }
}