import axios from 'axios';

const surveyForm = () => {
  $(document).on('change', '.surveyForm .batteriesNeeded select', function(){
    if ($(this).val() != 'Si') {
      $('.surveyForm .batteriesBrand, .surveyForm .batteriesModel').addClass('d-none');
      return;
    }

    $('.surveyForm .batteriesBrand').removeClass('d-none');
  });

  $(document).on('change', '.surveyForm .batteriesBrand select', function(){
    axios.get(`/brands/${$(this).val()}/batteries.json`).then(function (response) {
      $('.surveyForm .batteriesModel select option').remove();
      $('.surveyForm .batteriesModel select option').remove();
      response.data.components.forEach(function(component){
        $('.surveyForm .batteriesModel select').append(`<option value="${component.id}">${component.model}</option>`)
      });
      $('.surveyForm .batteriesModel').removeClass('d-none');
    });
  });
}

export default surveyForm;
