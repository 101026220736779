import consumer from "./consumer"
import toastr from "toastr"
import Swal from 'sweetalert2'
import 'toastr/build/toastr.min.css';

// Helper function to create elements with classes and attributes
const createElement = (tag, { classList = [], attributes = {} } = {}) => {
  const element = document.createElement(tag);
  classList.forEach((cls) => element.classList.add(cls));
  Object.entries(attributes).forEach(([key, value]) => element.setAttribute(key, value));
  return element;
};

// Helper function to update the count
const updateCount = (typeDocument, increment = 1) => {
  const spanCount = document.getElementById(`count_${typeDocument}`);
  if (spanCount) {
    spanCount.textContent = parseInt(spanCount.textContent, 10) + increment;
  }
};

// Function to create the file wrapper
const createFileWrapper = (data) => {
  const fileWrapper = createElement("div", { classList: ["position-relative"] });

  // Picture Anchor
  const picAnchor = createElement("a", {
    attributes: {
      href: data.url,
      target: "_blank",
      rel: "noopener noreferrer",
    },
  });

  const image = createElement("img", {
    classList: ["w-100"],
    attributes: { src: data.image_url },
  });
  picAnchor.appendChild(image);
  fileWrapper.appendChild(picAnchor);

  // Delete Button
  const deleteButton = createElement("a", {
    classList: ["position-absolute"],
    attributes: {
      style: "right: 0.5rem; top: 0.5rem;",
      "data-confirm": "Estás seguro de eliminar esta foto?",
      "data-method": "delete",
    },
  });

  const icon = createElement("i", { classList: ["fe", "fe-trash"] });
  deleteButton.appendChild(icon);
  fileWrapper.appendChild(deleteButton);

  return fileWrapper;
};

// Function to handle new file container
const handleNewContainer = (data, fileWrapper) => {
  const { type_document: typeDocument } = data;
  const documentsContainer = document.getElementById(`documents-${typeDocument}`);

  // Collapse Link
  const collapseLink = createElement("a", {
    classList: ["ms-3"],
    attributes: {
      href: `#collapse_${typeDocument}`,
      "data-bs-toggle": "collapse",
      role: "button",
      "aria-expanded": "false",
      "aria-controls": `collapse_${typeDocument}`,
    },
  });
  collapseLink.textContent = "Ver fotos (";

  const spanCount = createElement("span", {
    attributes: { id: `count_${typeDocument}` },
  });
  spanCount.textContent = "1";

  const spanChevron = createElement("span", { classList: ["fe", "fe-chevron-down"] });

  collapseLink.appendChild(spanCount);
  collapseLink.appendChild(document.createTextNode(") "));
  collapseLink.appendChild(spanChevron);

  // File Container
  const newFileContainer = createElement("div", {
    classList: ["collapse"],
    attributes: { id: `collapse_${typeDocument}` },
  });

  const cards = createElement("div", { classList: ["cards"] });
  newFileContainer.appendChild(cards);
  cards.appendChild(fileWrapper);

  // Append to documents container
  documentsContainer.appendChild(collapseLink);
  documentsContainer.appendChild(newFileContainer);
};

// Function to handle existing file container
const handleExistingContainer = (fileContainer, fileWrapper, typeDocument) => {
  const cards = fileContainer.querySelector(".cards");
  if (cards) {
    cards.appendChild(fileWrapper);
    updateCount(typeDocument);
  }
};

// Function to display toastr notification
const showToastr = () => {
  toastr.success("El archivo ha sido subido", "Archivo subido", {
    timeOut: 5000,
    positionClass: "toast-bottom-right",
    newestOnTop: true,
    preventDuplicates: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
  });
};



consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    console.log("Connected to NotificationsChannel")
  },

  disconnected() {
    console.log("Disconnected from NotificationsChannel")
  },

  received(data) {
    if (data['error']) {
      toastr.error(data['error'], { timeOut: 5000 });
    } else if (data['notification']) {
      Swal.fire({
        title: 'Atención!',
        html: data['notification']['text'],
        confirmButtonText: data['notification']['confirmButtonText'] || 'OK',
      }).then(function(result) {
        if (result.isConfirmed && data['notification']['redirectUrl']) {
          window.location = data['notification']['redirectUrl'];
        }
      });
    } else {
      const { type_document: typeDocument } = data;
      let fileContainer = document.getElementById(`collapse_${typeDocument}`);
      const fileWrapper = createFileWrapper(data);

      if (fileContainer) {
        handleExistingContainer(fileContainer, fileWrapper, typeDocument);
      } else {
        handleNewContainer(data, fileWrapper);
      }

      showToastr();
    }
  },
})
