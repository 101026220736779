import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['roofOrientation', 'consumption', 'panelsAmount']

  updatePanelsAmount() {
    let factorCorrector = this.roofOrientationTarget.options[this.roofOrientationTarget.selectedIndex].dataset.factorCorrector;
    this.panelsAmountTarget.value = calculatePanelsAmount(this.consumptionTarget.value, factorCorrector)
  }
}

function calculatePanelsAmount(consumption, roofOrientationFactor) {
  return Math.ceil(parseFloat(consumption * roofOrientationFactor) / 445);
}